@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Your custom styles here */
body, html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

.calm-bg {
  background: linear-gradient(to right, #f0f4f8, #d9e2ec);
}

.font-serif {
  font-family: 'Cormorant Garamond', serif;
}

/* Add any other custom styles you need */